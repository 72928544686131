import React from "react";
import {
  getDateString,
  lookup,
  getOrganizers,
  getDataByValue,
} from "utils/common";
import {
  booleanOptions,
  locationScope,
  organizationTemplateOption,
  sessionStatuses,
  emailTempStatus,
  genderData,
} from "utils/constants";

import IconFilePdf from "assets/icons/icon-file-pdf.svg";
import IconFileWord from "assets/icons/icon-file-word.svg";
import IconFileImg from "assets/icons/icon-file-image.svg";
import IconAudioFile from "assets/icons/icon-audio-file.svg";
import IconVideoFile from "assets/icons/icon-video-file.svg";
import IconPDFFile from "assets/icons/icon-pdf-file.svg";
import IconHTMLFile from "assets/icons/icon-html-file.svg";
import { statusData } from "utils/constants";
import { capitalize, upperFirst } from "lodash";

export const statusTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  rowData[field] =
    typeof rowData[field] === "object" ? rowData[field]?.value : rowData[field];
  const { status, statusClass } =
    rowData[field] && statusData[rowData[field].toUpperCase()]
      ? statusData[rowData[field].toUpperCase()]
      : "";

  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={status}
      >
        <div className={statusClass}> {status} </div>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={status}>
        <div className={statusClass}> {status} </div>
      </div>
    </>
  );
};

export const statusListingTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const getStatus = (objList) => {
    if (objList && objList?.length > 0) {
      const { status } = objList[0];
      return status;
    }
    return null;
  };
  const { status, statusClass } =
    rowData[field] && statusData[getStatus(rowData[field])?.toUpperCase()]
      ? statusData[getStatus(rowData[field])?.toUpperCase()]
      : "";

  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={status}
      >
        <div className={statusClass}> {status} </div>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={status}>
        <div className={statusClass}> {status} </div>
      </div>
    </>
  );
};

export const emailTempStatusBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let newStatus = emailTempStatus[rowData[field]] || "";
  const { status, statusClass } =
    rowData[field] && statusData[newStatus.toUpperCase()]
      ? statusData[newStatus.toUpperCase()]
      : "";

  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={status}
      >
        <div className={statusClass}> {status} </div>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={status}>
        <div className={statusClass}> {status} </div>
      </div>
    </>
  );
};

export function IdStatusTemplate(
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) {
  let status = "";
  let statusClass = "";
  if (rowData[field]) {
    status = capitalize(rowData[field]?.replaceAll("_", " "));
    if (rowData[field] === "failed") {
      statusClass = "p-badge status-in-active";
    }
  }
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={status}
      >
        <div className={statusClass}> {status} </div>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={status}>
        <div className={statusClass}> {status} </div>
      </div>
    </>
  );
}

export const sessionStatusTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let status = "";
  let statusClass = "";

  if (rowData[field] === "OPEN") {
    status = "OPEN";
    statusClass = "p-badge status-active";
  } else if (!rowData[field] || rowData[field] === "CLOSED") {
    status = "CLOSED";
    statusClass = "p-badge status-in-active";
  }

  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={status}
      >
        <div className={statusClass}> {status} </div>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={status}>
        <div className={statusClass}> {status} </div>
      </div>
    </>
  );
};

export const conAdminStatusTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let status = "";
  let statusClass = "";

  if (rowData[field] === true) {
    status = "Active";
    statusClass = "p-badge status-active";
  } else {
    status = "Inactive";
    statusClass = "p-badge status-in-active";
  }

  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={status}
      >
        <div className={statusClass}> {status} </div>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={status}>
        <div className={statusClass}> {status} </div>
      </div>
    </>
  );
};

export const attachmentTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={status}
      >
        <div>
          <img src={rowData[field]} width="120" />
        </div>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td">
        <div>
          {" "}
          <img src={rowData[field]} width="120" />{" "}
        </div>
      </div>
    </>
  );
};

export const getValueFromObj = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let cellValue = null;
  if (typeof rowData[field] === "object")
    cellValue = rowData[field]?.name ? rowData[field].name : "";
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
    </>
  );
};

export const displayNameTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  },
  names
) => {
  const displayName = names[rowData[field]]?.label || "";
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={displayName}
      >
        {displayName}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={displayName}
      >
        {displayName}
      </div>
    </>
  );
};

export function ObjArrayBadge(rowData, { field }) {
  if (Array.isArray(rowData[field])) {
    return (
      <ul className="p-pl-2">
        {rowData[field].map(({ name, id }) => {
          return (
            <li key={id} title={name || ""}>
              {" "}
              {name || ""}{" "}
            </li>
          );
        })}
      </ul>
    );
  }
  return "";
}

export const createdDateBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  },
  dateFormat
) => {
  const value = lookup(rowData, field);
  const date = getDateString(value, dateFormat);
  return (
    <>
      <div className="p-column-title"> {header} </div>
      {value ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
            title={date}
          >
            {date}
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td" title={date}>
            {date}
          </div>
        </>
      ) : (
        <div className="hfn-datatable-td"> </div>
      )}
    </>
  );
};

export const boolBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let cellValue = null;
  if (typeof rowData[field] === "boolean")
    cellValue = rowData[field] ? "Yes" : "No";
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
    </>
  );
};

export const getLabelByValue = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let cellValue = null;
  if (rowData[field]) {
    cellValue = locationScope[rowData[field]];
  }
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
    </>
  );
};

export const locationBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let cellValue = null;
  if (rowData?.village) cellValue = rowData?.village?.name;
  else cellValue = rowData?.city?.name;
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
    </>
  );
};
export const getOrganizersBadge = (
  rowData,
  {
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const cellValue = getOrganizers(rowData);
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
    </>
  );
};
export const cityACTemplate = (
  { name, sub_state, state, country, complete_name },
  { context }
) => {
  const completeName = complete_name ? complete_name?.split("/") : [];
  let taluk = "";
  let district = "";
  if (completeName?.length > 0) {
    if (completeName?.length === 3) {
      [, taluk] = completeName;
      [district] = completeName;
    }
    if (completeName?.length === 2) {
      [taluk] = completeName;
    }
  }
  const stateName = typeof state === "object" ? state?.name : state || "";
  const subStateName =
    typeof sub_state === "object" ? sub_state?.name : sub_state || "";
  const countryName =
    typeof country === "object" ? country?.name : country || "";

  if (context === "menu")
    return (
      <div className="cac-wrapper">
        <div className="cac-name">{name}</div>
        <div className="cac-sc-wrapper">
          <span className="cac-sc">
            {taluk ? `${taluk}, ` : ``}
            {district ? `${district}, ` : ``}
            {subStateName ? `${subStateName}, ` : ``}
            {stateName}
            {stateName ? <span>&nbsp;,&nbsp;</span> : <></>}
            {countryName}
          </span>
        </div>
      </div>
    );
  return name
    ? name +
        (taluk ? `, ${taluk}` : "") +
        (district ? `, ${district}` : "") +
        (subStateName ? `, ${subStateName}` : "") +
        (stateName ? `, ${stateName}` : "") +
        (countryName ? `, ${countryName}` : "")
    : null;
};
export const medACTemplate = (center) => {
  return (
    <div className="cac-wrapper">
      <div className="cac-name">{center?.name}</div>
    </div>
  );
};

export const villageACTemplate = (
  { label, subdistrict, state },
  { context }
) => {
  if (context === "menu")
    return (
      <div className="cac-wrapper">
        <div className="cac-name">{label}</div>
        <div className="cac-sc-wrapper">
          <span className="cac-sc">
            {subdistrict?.name ? `${subdistrict?.name}, ` : ``}
            {state?.name}
          </span>
        </div>
      </div>
    );
  return label
    ? label +
        (subdistrict?.name ? `, ${subdistrict?.name}` : "") +
        (state?.name ? `, ${state?.name}` : "")
    : null;
};

export const submittedDateTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const value = lookup(rowData, field);
  let date = getDateString(value, "MMM DD, YYYY HH:mm A") || "";
  if (
    [sessionStatuses?.live?.value, sessionStatuses?.pendingDc?.value].includes(
      rowData?.sessionStatus
    )
  )
    date = "";

  return (
    <>
      <div className="p-column-title"> {header} </div>
      {value ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
            title={date}
          >
            {date}
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td" title={date}>
            {date}
          </div>
        </>
      ) : (
        <div className="hfn-datatable-td"> </div>
      )}
    </>
  );
};

export const centerTemplate = (rowData, { field }) => {
  if (Array.isArray(rowData[field])) {
    return (
      <p className="p-pl-2">
        {rowData[field].map((item, key) =>
          rowData[field].length === key + 1
            ? item?.center.centerName
            : `${item?.center.centerName}, `
        )}
      </p>
    );
  }
  return "";
};

export const renderTestimonialIcons = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const fileType = [];
  const attachments = rowData[field] || [];
  let cellValue = "";
  const docType = { img: 0, doc: 0, pdf: 0 };
  const doctTypeImage = {
    img: IconFileImg,
    doc: IconFileWord,
    pdf: IconFilePdf,
  };
  if (attachments)
    Object.values(attachments).forEach((key) => {
      if (key.filename?.includes("image")) {
        docType.img += 1;
      } else if (
        key.filename?.includes("pdf") ||
        key.extension?.includes("pdf")
      ) {
        docType.pdf += 1;
      } else if (
        key.filename?.includes("doc") ||
        key.filename?.includes("word") ||
        key.extension?.includes("doc")
      ) {
        docType.doc += 1;
      } else {
        docType.img += 1;
      }
    });

  Object.keys(docType).forEach((key) => {
    if (docType[key])
      fileType.push(
        <span key={key}>
          <small
            style={{
              display: "inline-block",
              margin: "0 3px 0 3px",
              verticalAlign: "top",
            }}
          >
            {docType[key]}
          </small>
          <img
            src={doctTypeImage[key]}
            alt={key}
            width={key === "img" ? 24 : 18}
            className="testimonia-doc-count"
          />
        </span>
      );
  });

  cellValue = fileType;
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div className="hfn-datatable-td hfn-header-style-td" style={headerStyle}>
        {cellValue ?? ""}
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td">
        {cellValue ?? ""}
      </div>
    </>
  );
};

export const removeUnderscoreBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const value = lookup(rowData, field);
  const val = value?.toLowerCase();

  if (val === "invoice_receipt_template") {
    return (
      <>
        <div className="p-column-title"> {header} </div>
        {val ? (
          <>
            <div
              className="hfn-datatable-td hfn-header-style-td"
              style={headerStyle}
            >
              Invoice Template
            </div>
            <div className="hfn-datatable-td hfn-no-header-style-td">
              Invoice Template
            </div>
          </>
        ) : (
          <div className="hfn-datatable-td">-</div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="p-column-title"> {header} </div>
      {val ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
          >
            {upperFirst(val.replaceAll("_", " "))}
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td">
            {upperFirst(val.replaceAll("_", " "))}
          </div>
        </>
      ) : (
        <div className="hfn-datatable-td">-</div>
      )}
    </>
  );
};

export const organizationOptionsBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const value = lookup(rowData, field);
  const val = getDataByValue(organizationTemplateOption, value)?.label;

  return (
    <>
      <div className="p-column-title"> {header} </div>
      {val ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
          >
            {val}
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td">{val}</div>
        </>
      ) : (
        <div className="hfn-datatable-td"> </div>
      )}
    </>
  );
};

export const inventoryValueBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const value = lookup(rowData, field);
  const val = getDataByValue(booleanOptions, value)?.label;

  return (
    <>
      <div className="p-column-title"> {header} </div>
      {value ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
          >
            {value}
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td">{value}</div>
        </>
      ) : (
        <div className="hfn-datatable-td">0</div>
      )}
    </>
  );
};

export const booleanBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const value = lookup(rowData, field);
  const val = getDataByValue(booleanOptions, value)?.label;

  return (
    <>
      <div className="p-column-title"> {header} </div>
      {val ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
          >
            {val}
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td">{val}</div>
        </>
      ) : (
        <div className="hfn-datatable-td"> </div>
      )}
    </>
  );
};

export const renderIcons = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const value = lookup(rowData, field);
  const type = lookup(rowData, "daajis_message_block-message_type");
  const file = lookup(rowData, "daajis_message_block-message_file");
  let src;
  switch (type) {
    case "pdf":
      src = IconPDFFile;
      break;
    case "html":
      src = IconHTMLFile;
      break;
    case "audio":
      src = IconAudioFile;
      break;
    case "video":
      src = IconVideoFile;
      break;
    default:
      break;
  }

  return (
    <>
      {src && file ? (
        <>
          <div className="p-column-title"> {header} </div>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
          >
            <img
              src={src}
              alt={"img"}
              width={24}
              className="testimonia-doc-count"
            />
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td">
            <img
              src={src}
              alt={"img"}
              width={24}
              className="testimonia-doc-count"
            />
          </div>
        </>
      ) : (
        <div
          className="hfn-datatable-td hfn-header-style-td"
          style={headerStyle}
        ></div>
      )}
    </>
  );
};

export const bannerTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  return (
    <>
      {rowData[field] ? (
        <>
          <div className="p-column-title"> {header} </div>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
            title={status}
          >
            <div>
              <img
                className="hfn-header-style-td-img"
                src={rowData[field]}
                width="100px"
              />
            </div>
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td">
            <div>
              {" "}
              <img
                src={rowData[field]}
                className="hfn-header-style-td-img"
                width="100"
                height="100"
              />{" "}
            </div>
          </div>
        </>
      ) : (
        <div
          className="hfn-datatable-td hfn-header-style-td"
          style={headerStyle}
          title={status}
        >
          <div></div>
        </div>
      )}
    </>
  );
};

export const genderOptionsBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const value = lookup(rowData, field);
  const val = getDataByValue(genderData, value)?.label;

  return (
    <>
      <div className="p-column-title"> {header} </div>
      {val ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
          >
            {val}
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td">{val}</div>
        </>
      ) : (
        <div className="hfn-datatable-td"> </div>
      )}
    </>
  );
};
