// utils
import {
  checkKeycloakTokenIsExpired,
  logout,
  setFirebaseToken,
} from "utils/login";
import { lStorage } from "utils/storage";
import toaster from "utils/toaster";
import config from "assets/config";
import { upperFirst } from "lodash";
import { KEYCLOAK_LOGIN } from "utils/constants";
import { getRefreshToken } from "hfn-oneauth/main";

const interceptor = (ax, xClientIdSRCM = "", ignoreXClientId = false) => {
  const srcmConfig = JSON.parse(config.mysrcmConfig);
  const getErrorFromObj = (objData) => {
    const objKeys = Object.keys(objData);
    return `${upperFirst(objKeys[0].replaceAll("_", " "))}: ${
      objData[objKeys[0]]
    }`;
  };

  const refreshKeycloak = async () => {
    try {
      const authInfo = lStorage.get("authInfo");
      const hfnAuthInfo = localStorage.getItem("hfnAuthInfo");
      if (!authInfo?.srcmToken && !hfnAuthInfo) return false;
      const params = JSON.parse(config?.keycloakAuthConfig);
      const res = await getRefreshToken(params).catch(() => {
        // console.log(e);
      });
      if (res?.data?.access_token) {
        setFirebaseToken(res?.data?.access_token);
        localStorage.setItem("loginDateTime", new Date());
        return res?.data?.access_token;
      }
      await logout();
    } catch {
      // console.log();
    }

    return null;
  };

  ax.interceptors.request.use(
    async (configData) => {
      const authInfo = lStorage.get("authInfo");
      let authToken = null;

      if (config?.loginType === KEYCLOAK_LOGIN && authInfo?.srcmToken) {
        if (checkKeycloakTokenIsExpired(authInfo)) {
          const newToken = (await refreshKeycloak()) || authInfo.srcmToken;
          if (newToken) {
            localStorage.setItem("loginDateTime", new Date());
            authToken = newToken;
          }
        } else {
          authToken = authInfo.srcmToken;
        }
      }

      if (authToken) {
        const configDataNew = configData;
        if (!ignoreXClientId) {
          configDataNew.headers["x-client-id"] =
            xClientIdSRCM || srcmConfig.xClientId;
        }
        configDataNew.headers.Authorization = `Bearer ${authToken}`;
      }

      return configData;
    },
    (error) => Promise.reject(error)
  );

  ax.interceptors.response.use(
    (next) => Promise.resolve(next),
    async (error) => {
      if (error.response && error.response.status === 401) {
        await logout();
      } else if (error.response && error.response.status === 400) {
        const err = Array.isArray(error?.response?.data)
          ? {
              non_field_errors:
                error?.response?.data[0]?.non_field_errors[0] ||
                "Invalid input data",
            }
          : error?.response?.data;

        const errorObj = typeof err === "object" ? getErrorFromObj(err) : "";
        const errorMessage = errorObj || "Invalid input data";
        toaster.custom({
          severity: "error",
          summary: "Error:",
          detail: Array.isArray(errorMessage) ? errorMessage[0] : errorMessage,
          life: 5000,
        });
      } else {
        toaster.custom({
          severity: "error",
          summary: "Error:",
          detail: error?.response?.data?.message || error.message,
          life: 5000,
        });
      }
      return Promise.reject(error);
    }
  );
};

export default interceptor;
