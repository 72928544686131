import { BasicConfig } from "@react-awesome-query-builder/ui";

export const lazyDelayTimeout = 500;
export const MAX_NUMBER_OF_FILES = 50;
export const COUNTRY_INDIA = 358;
export const ACCEPTED_IMG_FILES = ["image/png", "image/jpeg", "image/jpg"];
export const NOTAPPLICABLE = "not_applicable";
export const FAILED = "failed";
export const GENERATED = "generated";
export const ALREADY_GENERATED = "already_generated";
export const SESSION_REG_SHORTURLTXT = "hfn.link/self-attendance";
export const SESSION_REG_SHORTURL = "https://hfn.link/self-attendance";
export const SESSION_REG_NON_CONNECT_SHORTURLTXT =
  "hfn.link/non-connect-self-attendance";
export const SESSION_REG_NON_CONNECT_SHORTURL =
  "/global-event-registration-non-connect";
export const SUPPORT_EMAIL = "uep.support@heartfulness.org";
export const ENTRY_CHANNEL_HEP = "hep";
export const ENTRY_CHANNEL_APP = "heartsapp";
export const DEFAULT_DATE_FORMAT = "MMM DD, YYYY";
export const ACCEPTED_TESTIMONIAL_FILES = [
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/jpg",
  "video/mp4",
  "video/quicktime",
  "video/hevc",
  "video/x-matroska",
  "video/webm",
  "video/x-msvideo",
  "video/x-flv",
  ".doc",
  ".docx",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const eventTypes = [
  { label: "Connect", value: "connect" },
  { label: "Non Connect", value: "non_connect" },
];

export const CONNECT_EVENT = "connect";
export const NON_CONNECT_EVENT = "non_connect";
export const PRE_REG_EVENT = "pre-reg-events";
export const REPORT_ONLY_EVENT = "report-only";
export const REPORT_ONLY = "report-only";
export const ROLE_EVENT_APPROVER = "uep_events_approver";
export const ROLE_LISTING_EVENT_APPROVER = "uep_events_listing_approver";
export const SUPER_ADMIN = "uep_super_admin";
export const ADMIN = "uep_admin";
export const PRECEPTOR = "prefect";
export const ABHYASI = "abhyasi";
export const PAYMENT_DOMESTIC = "domestic";
export const PAYMENT_INTL = "international";
export const paymentOptions = [
  {
    value: PAYMENT_DOMESTIC,
    label: "Indian Cards/banks/UPI",
  },
  {
    value: PAYMENT_INTL,
    label: "Overseas Cards/ International Payments(Outside India)",
  },
];

export const listOfApprover = [ROLE_EVENT_APPROVER, SUPER_ADMIN];

export const ACCEPTED_UPLOAD_FILES = [
  "application/csv",
  "application/x-csv",
  "text/csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values",
];
export const ACCEPTED_UPLOAD_FILES_EXCEL = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values",
];
export const IMAGE_EXTENSIONS = ["png", "jpg", "jpeg"];
export const DOC_PDF_EXTENSIONS = ["docx", "doc", "pdf"];
export const MAX_FILE_SIZE = 10000000;
export const isValidPnr = (pnr) => /^[A-Z]{2}-[A-Z0-9]{4}-[A-Z]{4}$/.test(pnr);
export const DATETIME_REG =
  /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?/;
export const eventSessionType = {
  true: "Online",
  false: "In Person",
};

export const isGovernmentEvent = {
  true: "Yes",
  false: "No",
};

export const locationScope = {
  one_city: "One city",
  multi_city: "Multi city",
  country_wide: "Country wide",
  global: "Global ",
};

export const recurringFrequency = {
  sunday: "Sunday",
  "mon-sat": "Monday to Saturday",
  "mon-fri": "Monday to Friday",
  daily: "Daily ",
  "one-time": "One time ",
};

/// ////////// ENds /////////////

export const idTypes = {
  pan: { label: "PAN Card", value: "Pan Card" },
};

export const idTypeByName = Object.fromEntries(
  Object.values(idTypes).map((item) => [item.value, item])
);

export const generalStatuses = {
  active: { label: "Pending", value: "pending" },
  inactive: { label: "Approved", value: "approved" },
};

export const eventStatuses = {
  draft: { label: "Draft", value: "draft" },
  pending: { label: "Pending", value: "pending" },
  approved: { label: "Approved", value: "approved" },
  rejected: { label: "Rejected", value: "rejected" },
  waitlisted: { label: "Waitlisted", value: "waitlisted" },
};

export const eventStatusesForApprover = {
  pending: { label: "Pending", value: "pending" },
  approved: { label: "Approved", value: "approved" },
  rejected: { label: "Rejected", value: "rejected" },
};

// status for active and inactive for prereg
export const eventActive = {
  active: { label: "Active", value: "true" },
  inactive: { label: "Inactive", value: "false" },
};

export const eventActiveStatus = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const registrantStatuses = {
  pending: { label: "Pending", value: "pending" },
  confirmed: { label: "Confirmed", value: "confirmed" },
  cancelled: { label: "Cancelled", value: "cancelled" },
  not_approved: { label: "Not Approved", value: "not_approved" },
  waitlisted: { label: "Waitlisted", value: "waitlisted" },
};

export const masterDataStatuses = {
  active: { label: "Active", value: "true" },
  inactive: { label: "Inactive", value: "false" },
};

export const connectAdminStatuses = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];

export const generalStatusByName = Object.fromEntries(
  Object.values(generalStatuses).map((item) => [item.value, item])
);

export const sessionStatuses = {
  live: { label: "Live", value: "LIVE_SESSION" },
};

export const sessionStatusByName = Object.fromEntries(
  Object.values(sessionStatuses).map((item) => [item.value, item])
);

export const batchStatuses = {
  toBeDeposited: { label: "To be deposited", value: "TO_BE_DEPOSITED" },
  deposited: { label: "Deposited", value: "DEPOSITED" },
};

export const batchStatusByName = Object.fromEntries(
  Object.values(batchStatuses).map((item) => [item.value, item])
);

export const receiptStatuses = {
  confirmed: { label: "Confirmed", value: "CONFIRMED_RECEIPT" },
};

export const emailTempStatuses = {
  draft: { label: "Draft", value: "draft" },
  approved: { label: "Approved", value: "approved" },
  cancelled: { label: "Cancelled", value: "cancelled" },
};

export const confirmStatuses = {
  yes: { label: "Yes", value: "YES" },
  no: { label: "No", value: "NO" },
};

export const availabilityStatuses = {
  true: { label: "Yes", value: true },
  false: { label: "No", value: false },
};

export const templateStatuses = {
  approved: { label: "Approved", value: "APPROVED" },
  cancelled: { label: "Cancelled", value: "CANCELLED" },
};

export const emailTargetFilter = {
  default: { label: "Default", value: "default" },
};

export const emailTempStatusesByName = Object.fromEntries(
  Object.values(emailTempStatuses).map((item) => [item.value, item])
);

export const receiptStatusByName = Object.fromEntries(
  Object.values(receiptStatuses).map((item) => [item.value, item])
);

export const sortFields = {
  id: "ID",
  name: "NAME",
  code: "CODE",
};

export const sortOrders = {
  asc: "ASCENDING",
  desc: "DESCENDING",
};

export const CONNECT_ADMIN = "connect_admin";

export const HFN_COORDINATORS = "hfn_coordinator";
export const HFN_TRAINER = "hfn_trainer";

export const EVENT_COORDINATORS = ["hfn_coordinator", "client_coordinator"];

export const SessionFormUser = [
  {
    label: "HFN Volunteer",
    id: "1",
    name: "userType",
    value: "hfn_volunteer",
  },
  { label: "HFN Presenter", id: "2", name: "userType", value: "hfn_presenter" },
  {
    label: "HFN Coordinator",
    id: "3",
    name: "userType",
    value: "hfn_coordinator",
  },
];

export const SessionSearchUser = [
  { label: "HFN Trainer", id: "1", name: "userType", value: "hfn_trainer" },
  {
    label: "HFN Volunteer",
    id: "2",
    name: "userType",
    value: "hfn_volunteer",
  },
  { label: "HFN Presenter", id: "3", name: "userType", value: "hfn_presenter" },
  {
    label: "HFN Coordinator",
    id: "4",
    name: "userType",
    value: "hfn_coordinator",
  },
];

export const geoloctionData = [
  { label: "India", value: "india" },
  { label: "Asia", value: "asia" },
  { label: "Europe", value: "europe" },
  { label: "US", value: "us" },
  { label: "UK", value: "uk" },
  { label: "Rest of the world", value: "rest_of_the_world" },
];
export const defaultGeoLocation = { label: "India", value: "india" };
export const GOVT_FLAG_ENABLED_SECTOR = ["Police or Defence", "Government"];

export const sessionCoordinators = [
  "hfn_trainer",
  "hfn_volunteer",
  "hfn_presenter",
  "hfn_coordinator",
];

export const SESSION_HFN_TRAINER = "hfn_trainer";

export const locationBasedFields = [
  "session_block-city",
  "session_block-postal_code",
];

export const idCardData = {
  not_applicable: { label: "Not Applicable", value: "not_applicable" },
  pending: { label: "Pending", value: "pending" },
  in_progress: { label: "In Progress", value: "in_progress" },
  failed: { label: "Failed", value: "failed" },
  generated: { label: "Generated", value: "generated" },
  already_generated: {
    label: "Already Generated",
    value: "already_generated",
  },
};
export const regParticipants = {
  yes: { label: "Yes", value: "yes" },
  no: { label: "No", value: "no" },
};
export const registrationData = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];
export const genderData = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
  { label: "Info Not Available", value: "U" },
];
export const ageGroupDropdown = [
  { value: 4, label: "0-4" },
  { value: 9, label: "5-9" },
  { value: 14, label: "10-14" },
  { value: 17, label: "15-17" },
  { value: 24, label: "18-24" },
  { value: 29, label: "25-29" },
  { value: 35, label: "30-35" },
  { value: 39, label: "36-39" },
  { value: 44, label: "40-44" },
  { value: 49, label: "45-49" },
  { value: 54, label: "50-54" },
  { value: 59, label: "55-59" },
  { value: 64, label: "60-64" },
  { value: 69, label: "65-69" },
  { value: 74, label: "70-74" },
  { value: 79, label: "75-79" },
  { value: 84, label: "80-84" },
  { value: 89, label: "85-89" },
  { value: 94, label: "90-94" },
  { value: 99, label: "94-99" },
];
export const customValues = [
  { is_paid: { true: "yes", false: "no" } },
  { is_govt_related: { true: "yes", false: "no" } },
  { is_venue_online: { true: "Online", false: "In person" } },
  { is_village_related: { true: "yes", false: "no" } },
  { is_multibatch: { true: "yes", false: "no" } },
];
export const IF_FOUND_TEXT = "If found please return to";
export const SAHAJ_MARG = "Sahaj Marg Spirituality Foundation";
export const KANHA_ADDRESS =
  "Kanha Shanti Vanam | Kanha Village | Nandigama Mandal\nTelengana 509325 | info@heartfulness.org\nIndia: 1 800 121 3492 | WhatsApp: +91 78710 77888";
export const fileType = [
  { label: "Pdf", value: "pdf" },
  { label: "Audio", value: "audio" },
  { label: "Video", value: "video" },
  { label: "HTML", value: "html" },
];
export const language = [
  { label: "English", value: "english" },
  { label: "Hindi", value: "hindi" },
  { label: "Tamil", value: "tamil" },
  { label: "Telugu", value: "telugu" },
];

export const languageOption = [
  { value: "en", label: "English" },
  { value: "hi", label: "हिंदी" },
];
export const HIDE_MINIMAL_FIELDS = ["category", "sector"];

export const currencyOption = [
  {
    value: "INR",
    label: "INR",
  },
];

export const typeOption = [
  {
    value: "SIMPLE_SKU",
    label: "SIMPLE SKU",
  },
  {
    value: "GROUP_SKU",
    label: "GROUP SKU",
  },
];

export const enabledOptions = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "Inactive",
  },
];

export const booleanOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

export const organizationTemplateOption = [
  {
    value: "Aaram",
    label: "Aaram",
  },
  {
    value: "Heartfulness_Education_Trust",
    label: "Heartfulness Education Trust",
  },
  {
    value: "Heartfulness_Institute",
    label: "Heartfulness Institute",
  },
  {
    value: "Wellness_Trust",
    label: "Wellness Trust",
  },
  {
    value: "Suruchi_Cafe",
    label: "Suruchi Cafe",
  },
];
export const BOOLEAN_FORM_FIELDS = [
  "display_on_calender",
  "is_paid_event",
  "recurrence_required",
  "is_govt_related",
  "is_village_related",
];

export const SECTOR_DEPENDANT_FIELDS = [
  "organization",
  "category",
  "program_type",
];

export const dateFields = [
  "event_block-start_datetime",
  "event_block-end_datetime",
  "session_block-start_datetime",
  "session_block-end_datetime",
];

export const compareFields = [
  "event_block-end_datetime",
  "session_block-end_datetime",
];

export const dateFieldsOnCard = ["fromDate", "toDate"];

export const dormTypeOption = [
  {
    value: "central-block",
    label: "Central Block",
  },
  {
    value: "north-training-hall",
    label: "North Training Hall",
  },
  {
    value: "north-block",
    label: "North Block",
  },
  {
    value: "south-block",
    label: "South Block",
  },
  {
    value: "east-dormitories",
    label: "East Dormitories",
  },
  {
    value: "the-pearl-hotel",
    label: "The Pearl Hotel",
  },
  {
    value: "wellness-center",
    label: "Wellness Center",
  },
  {
    value: "others",
    label: "Others",
  },
];

export const statusData = {
  ACTIVE: { status: "Active", statusClass: "p-badge status-active" },
  INACTIVE: { status: "Inactive", statusClass: "p-badge status-in-active" },
  DRAFT: { status: "Draft", statusClass: "p-badge in-progress" },
  CLOSED: { status: "Inactive", statusClass: "p-badge status-closed" },
  PENDING: { status: "Pending", statusClass: "p-badge status-pending" },
  PUBLISHED: { status: "Published", statusClass: "p-badge status-published" },
  REJECTED: { status: "Rejected", statusClass: "p-badge status-rejected" },
  NOT_APPROVED: {
    status: "Not Approved",
    statusClass: "p-badge status-rejected",
  },
  APPROVED: { status: "Approved", statusClass: "p-badge status-approved" },
  CANCELLED: { status: "Cancelled", statusClass: "p-badge status-in-active" },
  CONFIRMED: { status: "Confirmed", statusClass: "p-badge status-approved" },
  WAITLISTED: { status: "Waitlisted", statusClass: "p-badge status-pending" },
};

export const disableEditEvent = ["pending"];
export const disableAddSession = ["pending", "approved"];
export const disableApproveEvent = ["pending", "approved"];
export const disableEditSessionDetail = ["pending"];

export const disableEditTemplateBlocks = [
  "accommodation_block",
  "registration_fees_block",
  "custom_fields",
];

export const yesNoOption = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const triggerOption = [
  { label: "Pending", value: "pending" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Not Approved", value: "not_approved" },
  { label: "Waitlisted", value: "waitlisted" },
];

export const registrantStatusOption = [
  { label: "Pending", value: "pending" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Not Approved", value: "not_approved" },
];

export const emailTempStatusOption = [
  { label: "Active", value: "approved" },
  { label: "Inactive", value: "cancelled" },
];

export const emailTempStatus = { approved: "active", cancelled: "inactive" };

export const participantStatus = [
  { label: "Confirmed", value: "confirmed" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Pending", value: "pending" },
  { label: "Not Approved", value: "not_approved" },
];

export const operatorOptions = {
  select_equals: "==",
  select_not_equals: "!=",
  AND: "&&",
  OR: "||",
};

export const config = {
  ...BasicConfig,
  operators: {
    select_equals: {
      label: "==",
      labelForFormat: "==",
      sqlOp: "=",
      spelOp: "==",
      spelOps: ["==", "eq"],
      reversedOp: "select_not_equals",
      jsonLogic: "==",
      elasticSearchQueryType: "term",
    },
    select_not_equals: {
      isNotOp: true,
      label: "!=",
      labelForFormat: "!=",
      sqlOp: "<>",
      spelOp: "!=",
      spelOps: ["!=", "ne"],
      reversedOp: "select_equals",
      jsonLogic: "!=",
    },
  },

  fields: {
    stayPreference: {
      label: "Stay Preference",
      type: "select",
      valueSources: ["value"],
      fieldSettings: {
        listValues: [],
      },
    },
  },
  settings: {
    ...BasicConfig.settings,
    showNot: false,
    canReorder: false,
  },
};

export const regLimitStatusOption = [
  { label: "Pending", value: "pending" },
  { label: "Confirmed", value: "confirmed" },
];

export const commentsAvailFor = ["approved", "rejected"];

export const templateTypeOptions = [
  {
    value: "confirmation_email_template",
    label: "Confirmation Email Template",
  },
  {
    value: "confirmation_receipt_template",
    label: "Confirmation Receipt Template",
  },
  {
    value: "cancellation_email_template",
    label: "Cancellation Email Template",
  },
  {
    value: "cancellation_receipt_template",
    label: "Cancellation Receipt Template",
  },
  {
    value: "invoice_receipt_template",
    label: "Invoice Template",
  },
  {
    value: "otp_email_template",
    label: "OTP Email Template",
  },
];

export const fileUrls = {
  BANNER:
    "https://heartfulness.org/kanha/static/4624de1c23e399a49d786f168c6e61e9/f240e/Website-banner.jpg",
  MOBILE_BANNER:
    "https://eventreg.staging.heartfulness.org/_next/image?url=%2Fimages%2Fbg-mobile.webp&w=3840&q=75",
  PDF: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf ",
};

export const propertiesList = [
  "participant_constraints-condition",
  "participant_constraints-error_message",
  "participant_constraints-field_type",
  "participant_constraints-value",
];

export const PRE_REG_EVENT_APPROVAL = "pre-reg-events-approval";

export const paymentServiceOptionList = [
  { value: "razorpay_het", label: "Razorpay Het" },
  { value: "razorpay", label: "Razorpay Aaram" },
  { value: "razorpay_hfi", label: "Razorpay HFI" },
  { value: "payu_hfn", label: "Payu HFI" },
  { value: "instamojo_hfi", label: "Instamojo HFI" },
  { value: "razorpay_kanha_wellness", label: "Razorpay Kanha Wellness" },
  { value: "razorpay_suruchi_cafe", label: "Razorpay Suruchi Cafe" },
];

export const aaramPaymentServices = ["razorpay"];
export const heartfulnessInstitutePaymentServices = [
  "razorpay_hfi",
  "payu_hfn",
  "instamojo_hfi",
];
export const wellnessTrustPaymentServices = ["razorpay_kanha_wellness"];
export const suruchiCafe = ["razorpay_suruchi_cafe"];
export const hetPaymentServices = ["razorpay_het"];
export const specialPerformance = [
  {
    label: "Special Performance",
    value: "Special Performance",
  },
  {
    label: "Special Guest",
    value: "Special Guest",
  },
  {
    label: "Trainer",
    value: "Trainer",
  },
];

export const batchOptions = [
  { label: "Batch 1", value: "batch-1" },
  { label: "Batch 2", value: "batch-2" },
  { label: "Batch 3", value: "batch-3" },
  { label: "Batch 4", value: "batch-4" },
  { label: "Batch 5", value: "batch-5" },
];
export const fieldTypeOptions = {
  InputText: { value: "text", label: "Text" },
  InputTextarea: { value: "textarea", label: "Textarea" },
  mobile: { value: "phone", label: "Mobile" },
  email: { value: "email", label: "Email" },
  pinCode: { value: "pincode", label: "Pincode" },
  datePicker: { value: "calendar", label: "Date Picker" },
  dropdown: { value: "dropdown", label: "Dropdown" },
  InputZipcode: { value: "zipcode", label: "Zipcode" },
};

export const dynamicFieldTypeOptions = {
  InputText: { value: "text", label: "Text" },
  InputTextarea: { value: "textarea", label: "Textarea" },
  mobile: { value: "phone", label: "Mobile" },
  email: { value: "email", label: "Email" },
  pinCode: { value: "pincode", label: "Pincode" },
  datePicker: { value: "calendar", label: "Date Picker" },
  govtIdProof: { value: "govtid", label: "Govt Id Proof" },
  dropdown: { value: "dropdown", label: "Dropdown" },
  multiselect: { value: "multiselect", label: "Multiselect" },
  InputZipcode: { value: "zipcode", label: "Zipcode" },
};

export const conditionOptions = {
  hideIfRefAvailable: {
    value: "hide_if_ref_available",
    label: "Hide if abhyasi id available",
  },
};

export const conditionFieldOptions = [
  {
    value: "hide_if_ref_available",
    label: "Hide if abhyasi id available",
  },
];

export const genderOptions = {
  M: { label: "Male", value: "M" },
  F: { label: "Female", value: "F" },
  U: { label: "Info Not Available", value: "U" },
};

export const stayOptions = {
  paid: {
    label: "Paid Accommodation in kanha",
    value: "paid_accommodation_in_kanha",
  },
  free: {
    label: "Free Accommodation in kanha",
    value: "free_accommodation_in_kanha",
  },
  own: {
    label: "Own Accommodation Arrangements",
    value: "own_accommodation_arrangements",
  },
  virtual: {
    label: "Attending Virtually",
    value: "attending_virtually",
  },
  pearl: {
    label: "Paid accommodation at Pearl",
    value: "paid_accommodation_at_pearl",
  },
  northStudio: {
    label: "Paid accommodation in North Studio rooms",
    value: "paid_accommodation_in_north_studio_rooms",
  },
};

const dataSetValue = 6;

export const reportRls = {
  RF: { position: "region", dataSetval: dataSetValue },
  ZC: { position: "zone", dataSetval: dataSetValue },
  CC: { position: "center", dataSetval: dataSetValue },
  DEFAULT: { position: "region", dataSetval: dataSetValue },
};

export const filterByOptions = [
  { label: "City", value: "city" },
  { label: "Country", value: "country" },
  { label: "State", value: "state" },
];

export const restrictionNotes = `
  * Gender value must be a valid single or a list of comma seprated character (Eg: M,F).<br/>
  * Allowed Roles value must be a valid single or a list of comma seprated roles (Eg: a,t)<br/>
  "Seeker":s, "New Practitioner":n, "Practitioner":a, "Trainer":t.<br/>
  * Stay preference must be a valid single or a list of comma seprated stay pref. (Eg: free,paid,own,virtual).<br/>
  * Max participant value must be less than or equal to 6.
`;

export const paymentTypeOptions = [
  { value: "per_participant_basis", label: "Per Participant Basis" },
  { value: "per_event_basis", label: "Per Event Basis" },
];

export const regStatuses = [
  { label: "Approved", value: "confirmed" },
  { label: "Not Approved", value: "not_approved" },
];

export const PNR = "PNR";
export const INDIVIDUAL = "INDIVIDUAL";

export const gstTypeOption = [
  { label: "Inclusive", value: true },
  { label: "Exclusive", value: false },
];

export const APPROVED = "approved";

export const ARCHIVED_OPTIONS = [
  {
    value: "live",
    label: "Live Events",
  },
  {
    value: "future",
    label: "Future Events",
  },
  {
    value: "archived",
    label: "Archived Events",
  },
];

export const genderDropdownOptions = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
  { label: "Unspecified", value: "U" },
];

export const renameDownloadFields = {
  id: "Registration ID",
  ref: "Abhyasi ID",
  citizen_of_country: "Country",
  arrival_mode2: "Arrival Train / Flight No",
  departure_mode2: "Departure Train / Flight No",
  arrival_mode: "Pickup point / Transportation",
  departure_date: "Departure date",
  departure_mode: "Drop point / Transportation",
};

export const FIREBASE_LOGIN = "firebase";
export const KEYCLOAK_LOGIN = "keycloak";
export const FREE_ACCOMMODATION_IN_KANHA = "free_accommodation_in_kanha";

export const WAITINGLIST_STATUS = "waitlisted";

export const REGISTRANT_FIELDS =
  "id,create_date,write_date,name,ref,mobile,mobile2,email,email2,header,status,payment_status,partner,city,age_group,gender,stay_preference,language,citizen_of_country,has_oci,has_attended,is_spot_reg,date_of_joining,participant_json,identities,custom_field,custom_field2,custom_field3,custom_field4,photo_url,is_fav,pnr,event,session";

export const IMG_FIELDS = ["photo_url"];
export const daajiBlockComparisonData = "2024-08-01";
export const EKAATM_EVENT = "Ekaatm";
export const BASE_PATH_PROFILE = "profile";
export const CITY_SELECT_FIELD_TYPE = "citySelect";
export const EKAATM_PINCODE_FIELD = "custom_field";
