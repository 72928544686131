import React, { useEffect, useRef } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import AuthGuard from "auth-guard";
import Login from "components/login/Login";

import SelfRegistration from "components/SelfRegistration/SelfRegistration";
import GlobalRegistration from "components/GlobalRegistration/GlobalRegistration";
import PrintIdCard from "components/events/eventSessions/menuItems/PrintIdCard";
import Launch from "components/mobile/Launch/index";
import Refresh from "components/mobile/Launch/refresh";
import DownloadQr from "sharedComponents/downloadQr";
import LaunchDev from "components/mobile/Launch/LaunchDev";
import HFNErrorBoundary from "components/errorBoundary";

// shared components
import HFNLoader from "sharedComponents/lazyLoading";
import HFNToaster from "sharedComponents/toasters";
import HFNConfirmDialog from "sharedComponents/confirmDialog";

// utils
import { lazy } from "utils/lazy";

import {
  getLoginLocaluserData,
  isLoginAuth,
  logout,
  setLoginData,
} from "utils/login";

// config
import config from "assets/config";
import SelfAttendance from "components/SelfAttendance";
import Maintenance from "components/maintenance/Maintenance";
import Oneauth from "components/oneauth";
import { getHomePath } from "utils/common";

// lazy components
const LayoutContainer = lazy("layouts/Template");
const MobileLayoutContainer = lazy("layouts/PlainTemplate");
const UepLayoutTemplate = lazy("layouts/UepTemplate");
const HfnAppContainer = lazy("layouts/HfnAppTemplate");
const IS_MAINTENANCE = false;

function App() {
  const params = JSON.parse(config?.keycloakAuthConfig);
  const { pathname } = useLocation();
  const elementRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.includes("mobile")) {
      return;
    }
    const lsData = getLoginLocaluserData();
    if (
      lsData &&
      typeof lsData === "object" &&
      Object.keys(lsData).length > 0
    ) {
      setLoginData(lsData);
    }
    if (
      isLoginAuth() &&
      (pathname.includes("oneauth") || pathname.includes("login"))
    ) {
      const redirectTo = getHomePath();
      navigate(redirectTo);
    }
  }, []);
  const handleUserLoggedOut = async (event) => {
    if (event?.detail?.loggedOut) await logout();
  };
  useEffect(() => {
    elementRef.current = document.querySelector("hfn-oneauth");

    // Add the event listener when the element is present
    if (elementRef.current) {
      elementRef.current.addEventListener("userLoggedOut", handleUserLoggedOut);
    }

    // Cleanup event listener on unmount or when the path changes
    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener(
          "userLoggedOut",
          handleUserLoggedOut
        );
      }
    };
  }, [elementRef]);
  return (
    <HFNErrorBoundary>
      <HFNLoader>
        <hfn-oneauth
          ref={elementRef}
          config={JSON.stringify(params)}
          showCancel
          authType={!pathname?.includes("mobile") ? "" : "on-demand"}
        />
        <div className="hfn">
          {IS_MAINTENANCE ? (
            <Routes>
              <Route path="/login" element={<Maintenance />} />
              <Route
                path="/self-registration/:event/:session"
                element={<Maintenance />}
              />
              <Route
                path="/print-qr/:eventId/:sessionId/:blockId/:source/:type"
                element={<Maintenance />}
              />
              <Route
                path="/global-event-registration"
                element={<Maintenance />}
              />
              <Route
                path="/global-event-registration-non-connect"
                element={<Maintenance />}
              />
              <Route
                path="/non-connect-self-attendance/:eventId/:sessionId"
                element={<Maintenance />}
              />
              <Route
                path="/idcard-print/:cardNumber"
                element={<Maintenance />}
              />
              <Route
                path="/idcard-print/:event/:cardNumber"
                element={<Maintenance />}
              />
              <Route path="mobile/launch" element={<Launch />} />
              <Route path="mobile/launch/refresh" element={<Refresh />} />
              <Route path="mobile/launch-dev" element={<LaunchDev />} />
              <Route path="/mobile/*" element={<Maintenance />} />
              <Route path="/heartfulness-app/*" element={<Maintenance />} />
              <Route path="/users/*" element={<Maintenance />} />
              <Route
                path="*"
                element={<AuthGuard path="/" component={Maintenance} />}
              />
            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/oneauth/authorization" element={<Oneauth />} />
              <Route
                path="/self-registration/:event/:session"
                element={<SelfRegistration />}
              />
              <Route
                path="/print-qr/:eventId/:sessionId/:blockId/:source/:type"
                element={<DownloadQr />}
              />
              <Route
                path="/global-event-registration"
                element={<GlobalRegistration />}
              />
              <Route
                path="/global-event-registration-non-connect"
                element={<GlobalRegistration />}
              />
              <Route
                path="/non-connect-self-attendance/:eventId/:sessionId"
                element={<SelfAttendance />}
              />
              <Route
                path="/idcard-print/:cardNumber"
                element={<PrintIdCard />}
              />
              <Route
                path="/idcard-print/:event/:cardNumber"
                element={<PrintIdCard />}
              />
              <Route path="mobile/launch" element={<Launch />} />
              <Route path="mobile/launch/refresh" element={<Refresh />} />
              <Route path="mobile/launch-dev" element={<LaunchDev />} />
              <Route path="/mobile/*" element={<MobileLayoutContainer />} />
              <Route path="/heartfulness-app/*" element={<HfnAppContainer />} />
              <Route path="/users/*" element={<UepLayoutTemplate />} />
              <Route
                path="*"
                element={<AuthGuard path="/" component={LayoutContainer} />}
              />
            </Routes>
          )}
        </div>

        <div>
          <HFNToaster />
          <HFNConfirmDialog />
        </div>
      </HFNLoader>
    </HFNErrorBoundary>
  );
}

export default App;
