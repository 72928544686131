import { signOut } from "@heartfulnessinstitute/react-hfn-profile";
import config from "assets/config";
import { userLogout } from "hfn-oneauth/main";
import appStore from "store";
import { APP_FIREBASEUSER } from "store/actionTypes/app";
import { RESET, LOGOUT, UPDATE_INFO } from "store/actionTypes/login";
import { lStorage } from "utils/storage";
import { jwtDecode } from "jwt-decode";
import { FIREBASE_LOGIN } from "./constants";
import toaster from "./toaster";

export const getLoginID = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser?.id || null;
};

export const getLoginName = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser?.name || "";
};

export const getLoginRole = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser?.user_roles || [];
};

export const getLoginUserPositionCode = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser?.positionCode || "";
};

export const getLoginUserRole = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser || {};
};

export const getLoginUser = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser || {};
};

export const setLoginUser = (payload) => {
  lStorage.set("authInfo", payload);
};

export const getLoginLocaluserData = (authKey = "authInfo") => {
  return lStorage.get(authKey);
};

export const isLoginAuth = (authKey = "authInfo") => {
  const loginDetails = lStorage.get(authKey);
  return !!loginDetails?.srcmToken;
};

export const checkKeycloakTokenIsExpired = (authInfo) => {
  try {
    const decodedToken = jwtDecode(authInfo?.srcmToken);
    const loginDateTime = localStorage.getItem("loginDateTime") || null;
    if (loginDateTime) {
      const currentDateTime = new Date().getTime();
      const loggedInDateTime = new Date(loginDateTime).getTime();
      const activeDuration = currentDateTime - loggedInDateTime;
      const tokenValidity = Number(decodedToken.exp) - Number(decodedToken.iat);
      return activeDuration + 1000 > tokenValidity * 1000;
    }
    return true;
  } catch {
    return false;
  }
};

export const resetState = () => {
  appStore.dispatch({ type: RESET });
};

export const logout = async (showExpired = true) => {
  if (config?.loginType === FIREBASE_LOGIN) {
    lStorage.clear();
    signOut();
    resetState();
    if (showExpired) appStore.dispatch({ type: LOGOUT });
    return true;
  }
  const params = JSON.parse(config?.keycloakAuthConfig);
  const res = await userLogout(params);
  if (!res?.error) {
    lStorage.clear();
    signOut();
    resetState();
    if (showExpired) appStore.dispatch({ type: LOGOUT });
    return true;
  }
  if (res?.error && res?.message) {
    toaster.error(res?.message);
    return false;
  }
  return false;
};

export const setFirebaseUser = (payload) => {
  appStore.dispatch({ type: APP_FIREBASEUSER, payload });
};

export const getFirebaseUser = () => {
  return appStore.getState().appDetails.firebaseUser;
};

export const setLoginData = (payload) =>
  appStore.dispatch({ type: UPDATE_INFO, payload });

export const setFirebaseToken = (srcmToken) => {
  const loginDetails = lStorage.get("authInfo");
  if (loginDetails?.srcmToken && loginDetails.srcmToken !== srcmToken) {
    lStorage.set("authInfo", { ...loginDetails, srcmToken });
    setLoginData({ ...loginDetails, srcmToken });
  }
};

export const checkUserDataAdmin = () => {
  const userRoles = getLoginRole();
  if (userRoles.includes("uep_data_admin")) {
    return true;
  }
  return false;
};
