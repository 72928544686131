// services
import DropdownService from "services/dropdown/dropdown.service";
import { isNumericValue } from "./common";

export const getCitySuggestions = async (
  inputValue,
  callback,
  country = ""
) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  if (filterValue?.length < 3) return false;
  let suggestions = [];
  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    let countryName;
    let countryFilterValue;
    if (country && country !== null) {
      countryName = country.name.toLowerCase();
      countryFilterValue = `${countryName}/${filterValue.slice(0, 3)}`;
    } else {
      countryFilterValue = filterValue.slice(0, 3);
    }

    try {
      apiResponse = await dropdownService.getCachedCitySuggestions(
        countryFilterValue
      );
      const results = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
    } catch {
      try {
        apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
        suggestions = apiResponse.data;
      } catch {
        suggestions = [];
      }
    }
  }

  callback(suggestions || []);
};

let debounceTimeout;
export const getZipCodeSuggestions = async (
  inputValue,
  callback,
  city = "",
  debounceDelay = 500
) => {
  clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(async () => {
    const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
    if (!isNumericValue(filterValue) || filterValue?.length < 3) return false;

    let suggestions = [];
    if (filterValue) {
      const dropdownService = new DropdownService();
      let apiResponse;
      let zipcodeFilterValue;

      if (city && city !== null) {
        zipcodeFilterValue = `?city_id=${city?.id}&pincode__startswith=${filterValue}`;
      } else {
        zipcodeFilterValue = `?pincode__startswith=${filterValue}`;
      }

      try {
        apiResponse = await dropdownService.getCachedZipcode(
          zipcodeFilterValue
        );
        const { results } = apiResponse.data;

        for (const item of results) {
          if (item.pincode.toLowerCase().startsWith(filterValue)) {
            suggestions.push({
              ...item,
              value: item.pincode,
              label: item.pincode,
            });
            if (suggestions.length === 20) break;
          }
        }
      } catch {}
    }

    callback(suggestions || []);
  }, debounceDelay);
};

let debounceTimeoutCityZip;
export const getCityZipcodeSuggestions = async (
  inputValue,
  callback,
  zipcode = "",
  debounceDelay = 500
) => {
  clearTimeout(debounceTimeoutCityZip);

  debounceTimeoutCityZip = setTimeout(async () => {
    const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
    if (filterValue?.length < 3) return false;
    let suggestions = [];
    if (filterValue) {
      const dropdownService = new DropdownService();
      let apiResponse;
      let cityFilterValue;
      if (zipcode && zipcode?.id) {
        cityFilterValue = `?pincode_id=${zipcode?.id}&name__istartswith=${filterValue}`;
      }

      try {
        if (zipcode && zipcode?.id) {
          apiResponse = await dropdownService.getProfileCitySuggestions(
            cityFilterValue
          );
        }

        const { results } = apiResponse?.data || { results: [] };

        if (results?.length > 0) {
          for (const item of results) {
            if (
              item.active &&
              item.name.toLowerCase().startsWith(filterValue)
            ) {
              suggestions.push({
                ...item,
                value: item.id,
                label: item.name,
                state: item?.state?.name,
                country: item?.country?.name,
                sub_state: item?.sub_state?.name,
              });
              if (suggestions.length === 20) break;
            }
          }
        } else {
          apiResponse = await dropdownService.getCachedCitySuggestions(
            filterValue.slice(0, 3)
          );
          const results = apiResponse.data;
          if (results?.length > 0) {
            for (const item of results) {
              if (
                item.active &&
                item.name.toLowerCase().startsWith(filterValue)
              ) {
                suggestions.push({ ...item, value: item.id, label: item.name });
                if (suggestions.length === 20) break;
              }
            }
          }
        }
      } catch {
        try {
          apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
          suggestions = apiResponse.data;
        } catch {
          suggestions = [];
        }
      }
    }

    callback(suggestions || []);
  }, debounceDelay);
};

export const getCenterSuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];
  if (filterValue?.length) {
    const dropdownService = new DropdownService();
    let apiResponse;
    apiResponse = await dropdownService.getCachedCenterSuggestions(filterValue);
    const { results } = apiResponse.data;
    for (const item of results) {
      if (item.active && item.name.toLowerCase().includes(filterValue)) {
        suggestions.push({ ...item, value: item.id, label: item.name });
        if (suggestions.length === 20) break;
      }
    }
  }
  callback(suggestions || []);
};
export const getVillageSuggestions = async (
  inputValue,
  stateValue = null,
  callback
) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];
  if (filterValue && filterValue.length > 2) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      const payload = {
        type: 30,
        search: filterValue,
      };
      if (stateValue) payload.state = stateValue;
      apiResponse = await dropdownService.getCachedVillageSuggestions(payload);
      const { results } = apiResponse.data;
      for (const item of results) {
        suggestions.push({
          value: item.id,
          label: item.name,
          subdistrict: item.parent,
          state: item.state,
          city: item.city,
        });
        if (suggestions.length === 20) break;
      }
    } catch {
      return null;
    }
  }
  callback(suggestions || []);
};
export const getStateSuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  if (filterValue?.length < 3) return false;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      apiResponse = await dropdownService.getCachedStateSuggestions(
        filterValue.slice(0, 3)
      );
      const results = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }

      if (suggestions.length === 0) {
        try {
          apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
          suggestions = apiResponse.data.map((item) => ({
            ...item,
            value: item.state_id,
            label: item.state,
          }));
        } catch {
          return null;
        }
      }
    } catch {
      try {
        apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
        suggestions = apiResponse.data.map((item) => ({
          ...item,
          value: item.state_id,
          label: item.state,
        }));
      } catch {
        return null;
      }
    }
  }

  callback(suggestions || []);
};

export const getCountrySuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  if (filterValue?.length < 3) return false;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      apiResponse = await dropdownService.getCachedCountrySuggestions(
        filterValue.slice(0, 3)
      );
      const results = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }

      if (suggestions.length === 0) {
        try {
          apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
          suggestions = apiResponse.data.map((item) => ({
            ...item,
            value: item.country_id,
            label: item.country,
          }));
        } catch {
          return null;
        }
      }
    } catch {
      try {
        apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
        suggestions = apiResponse.data.map((item) => ({
          ...item,
          value: item.country_id,
          label: item.country,
        }));
      } catch {
        return null;
      }
    }
  }

  callback(suggestions || []);
};

export const getAshramSuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      apiResponse = await dropdownService.getAshramIdSuggestions(filterValue);
      const { results } = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase()) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
    } catch {}
  }
  callback(suggestions || []);
};

export const getZoneSuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      apiResponse = await dropdownService.getZoneSuggestions(filterValue);
      const { results } = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase()) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
    } catch {}
  }
  callback(suggestions || []);
};
